<template>
    <div>
        <ts-page-title
            :title="$t('driverClearCash.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('driverClearCash.pageTitle'),
                    href: '/admin/finance/driver-clear-cashes'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <ts-loading-banner :loading="fetch_package">
                    <div class="tw-flex tw-space-x-3">
                        <div class="tw-flex tw-items-center">
                            <label class="required">{{
                                $t("driverClearCash.date")
                            }}</label>
                            <div class="tw-flex-none tw-ml-3">
                                <a-date-picker
                                    v-model="model.transaction_date"
                                    :allowClear="false"
                                    format="DD-MM-YYYY"
                                    valueFormat="DD-MM-YYYY"
                                />
                                <div
                                    class="tw-text-red-500"
                                    v-if="errors.has('transaction_date')"
                                >
                                    {{ errors.first("transaction_date") }}
                                </div>
                            </div>
                        </div>
                        <div class="tw-flex tw-items-center">
                            <label class="required">{{
                                $t("driverClearCash.driverName")
                            }}</label>
                            <div class="tw-flex-none tw-ml-3">
                                <a-select
                                    show-search
                                    v-model="model.driver_id"
                                    style="width: 300px"
                                    :default-active-first-option="false"
                                    :show-arrow="false"
                                    :filter-option="false"
                                    :not-found-content="null"
                                    :placeholder="
                                        $t('driverClearCash.typeToSearch')
                                    "
                                    :loading="fetch_driver"
                                    @search="handleDriverSearch"
                                    @change="onDriverChange"
                                >
                                    <a-select-option
                                        v-for="c in driverList"
                                        :key="c.driver_id"
                                    >
                                        {{ c.driver_name }}
                                    </a-select-option>
                                </a-select>
                                <div
                                    class="tw-text-red-500"
                                    v-if="errors.has('driver_id')"
                                >
                                    {{ errors.first("driver_id") }}
                                </div>
                            </div>
                        </div>
                        <ts-button color="primary" @click.prevent="onSave">{{
                            $t("save")
                        }}</ts-button>
                        <ts-button
                            class="mr-2"
                            @click.prevent="
                                () =>
                                    $router.push(
                                        '/admin/finance/driver-clear-cashes'
                                    )
                            "
                        >
                            {{ $t("cancel") }}
                        </ts-button>
                    </div>
                    <br />
                    <div class="tw-overflow-x-scroll">
                        <div class="tw-w-full">
                            <table class="tw-w-full">
                                <thead>
                                    <tr class="tw-bg-blue-400 tw-text-white">
                                        <th
                                            rowspan="2"
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase"
                                        >
                                            {{ $t("numeric") }}
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase"
                                        >
                                            {{ $t("driverClearCash.shopCode") }}
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase"
                                        >
                                            {{ $t("driverClearCash.shopName") }}
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase"
                                        >
                                            {{
                                                $t("driverClearCash.driverCode")
                                            }}
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase"
                                        >
                                            {{
                                                $t("driverClearCash.driverName")
                                            }}
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase"
                                        >
                                            {{
                                                $t(
                                                    "driverClearCash.bookingNumber"
                                                )
                                            }}
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase"
                                        >
                                            {{
                                                $t(
                                                    "driverClearCash.receiverLocation"
                                                )
                                            }}
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase"
                                        >
                                            {{ $t("driverClearCash.phone") }}
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase"
                                        >
                                            {{ $t("driverClearCash.fxRate") }}
                                        </th>
                                        <th
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase tw-text-center"
                                            rowspan="2"
                                        >
                                            {{
                                                $t(
                                                    "driverClearCash.toBeCollectedUSD"
                                                )
                                            }}
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase tw-text-center"
                                        >
                                            {{
                                                $t(
                                                    "driverClearCash.collectedUSD"
                                                )
                                            }}
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase tw-text-center"
                                        >
                                            {{
                                                $t(
                                                    "driverClearCash.toBeCollectedKHR"
                                                )
                                            }}
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase tw-text-center"
                                        >
                                            {{
                                                $t(
                                                    "driverClearCash.collectedKHR"
                                                )
                                            }}
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase tw-text-center"
                                        >
                                            {{ $t("driverClearCash.taxiFee") }}
                                        </th>
                                        <th
                                            colspan="2"
                                            class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border tw-uppercase tw-text-center"
                                        >
                                            {{
                                                $t(
                                                    "driverClearCash.amountTobeClear"
                                                )
                                            }}
                                        </th>
																				<th
                                            class="tw-px-2 tw-py-1 tw-border tw-text-center tw-uppercase"
                                            rowspan="2"
                                        >
                                            {{ $t("driverClearCash.margin") }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th
                                            class="tw-px-2 tw-py-1 tw-border tw-text-center"
                                        >
                                            {{ $t("driverClearCash.usd") }}
                                        </th>
                                        <th
                                            class="tw-px-2 tw-py-1 tw-border tw-text-center"
                                        >
                                            {{ $t("driverClearCash.riel") }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(p, index) in packages"
                                        :key="index"
                                        :class="{
                                            'tw-text-red-500': isHightLight(p)
                                        }"
                                    >
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase tw-text-center"
                                        >
                                            {{ index + 1 }}
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            {{ p.shop_code }}
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            {{ p.shop_name }}
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            {{ p.driver_code }}
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            {{ p.driver_name }}
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            {{ p.booking_number }}
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            {{ p.receiver_location }}
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            {{ p.receiver_phone }}
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            <ts-accounting-format
                                                currency="KHR"
                                                :digit="0"
                                                :value="p.fx_rate"
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            <ts-accounting-format
                                                :digit="2"
                                                :value="p.amount_tobe_collected"
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            <ts-accounting-format
                                                :digit="2"
                                                :value="p.amount_collected_usd"
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            <ts-accounting-format
                                                currency="KHR"
                                                :digit="0"
                                                :value="
                                                    p.amount_tobe_collected_khr
                                                "
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            <ts-accounting-format
                                                currency="KHR"
                                                :digit="0"
                                                :value="p.amount_collected_khr"
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            <ts-accounting-format
                                                currency="KHR"
                                                :digit="0"
                                                :value="p.taxi_fee"
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            <ts-accounting-format
                                                :digit="2"
                                                :value="
                                                    p.amount_tobe_cleared_usd
                                                "
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            <ts-accounting-format
                                                currency="KHR"
                                                :digit="0"
                                                :value="
                                                    p.amount_tobe_cleared_khr
                                                "
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase"
                                        >
                                            <ts-accounting-format
                                                currency="KHR"
                                                :digit="0"
                                                :value="
                                                    p.amount_tobe_collected_khr - p.amount_collected_khr
                                                "
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr
                                        class="tw-bg-blue-400 tw-text-white tw-font-semibold"
                                    >
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase tw-text-right"
                                            colspan="9"
                                        >
                                            {{ $t("driverClearCash.total") }}
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase tw-text-right"
                                        >
                                            <ts-accounting-format
                                                :digit="2"
                                                :value="
                                                    sumTotal(
                                                        'amount_tobe_collected'
                                                    )
                                                "
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase tw-text-right"
                                        >
                                            <ts-accounting-format
                                                :digit="2"
                                                :value="
                                                    sumTotal(
                                                        'amount_collected_usd'
                                                    )
                                                "
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase tw-text-right"
                                        >
                                            <ts-accounting-format
                                                currency="KHR"
                                                :digit="0"
                                                :value="
                                                    sumTotal(
                                                        'amount_tobe_collected_khr'
                                                    )
                                                "
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase tw-text-right"
                                        >
                                            <ts-accounting-format
                                                currency="KHR"
                                                :digit="0"
                                                :value="
                                                    sumTotal(
                                                        'amount_collected_khr'
                                                    )
                                                "
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase tw-text-right"
                                        >
                                            <ts-accounting-format
                                                currency="KHR"
                                                :digit="0"
                                                :value="sumTotal('taxi_fee')"
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase tw-text-right"
                                        >
                                            <ts-accounting-format
                                                :digit="2"
                                                :value="
                                                    sumTotal(
                                                        'amount_tobe_cleared_usd'
                                                    )
                                                "
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase tw-text-right"
                                        >
                                            <ts-accounting-format
                                                currency="KHR"
                                                :digit="0"
                                                :value="
                                                    sumTotal(
                                                        'amount_tobe_cleared_khr'
                                                    )
                                                "
                                            />
                                        </td>
                                        <td
                                            class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-uppercase tw-text-right"
                                        >
                                            <ts-accounting-format
                                                currency="KHR"
                                                :digit="0"
                                                :value="sumMargin"
                                            />
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <br />
                    <div class="tw-w-64">
                        <textarea
                            v-model="model.remark"
                            cols="30"
                            rows="2"
                            class="form-control"
                            :placeholder="$t('driverClearCash.remark')"
                        ></textarea>
                    </div>
                </ts-loading-banner>
            </ts-panel-wrapper>
        </ts-panel>
        <a-modal
            :title="$t('pdf')"
            width="50%"
            v-model="preview_pdf"
            :footer="null"
        >
            <ts-preview-pdf v-model="loading" :src="src" />
        </a-modal>
    </div>
</template>

<script>
import moment from "moment";
import { Errors } from "form-backend-validation";
import { debounce, sumBy } from "lodash";
import { mapActions, mapState } from "vuex";
export default {
    name: "driverClearCashCreate",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            fetch_driver: false,
            preview_pdf: false,
            fetch_package: false,
            src: "",
            packages: [],
            model: {
                transaction_date: moment().format("DD-MM-YYYY"),
                driver_id: undefined,
                remark: ""
            }
        };
    },
    computed: {
        ...mapState("finance/driverClearCash", ["formModels"]),
        driverList() {
            return this.formModels.driver;
        },
        isHightLight: () => row => {
            return row.amount_tobe_collected_khr != row.amount_collected_khr;
        },
        sumTotal: vm => column => {
            var amount = sumBy(vm.packages, column);
            return amount;
        },
        sumMargin: vm => {
          return sumBy(vm.packages, o => o.amount_tobe_collected_khr - o.amount_collected_khr);
        }
    },
    methods: {
        ...mapActions("finance/driverClearCash", [
            "getFormViewData",
            "successPackage",
            "store"
        ]),
        handleDriverSearch: debounce(function(value) {
            this.fetch_driver = true;
            this.getFormViewData({
                params: {
                    fnName: "driver",
                    search_driver: value
                }
            }).finally(() => (this.fetch_driver = false));
        }, 500),
        onDriverChange: debounce(function(driverId) {
            this.fetch_package = true;
            this.packages = [];
            this.successPackage({
                driverId: driverId,
                transactionDate: this.model.transaction_date
            })
                .then(response => {
                    this.packages = response.data;
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => (this.fetch_package = false));
        }, 500),
        onSave() {
            this.loading = true;
            this.preview_pdf = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                    this.src = response.url;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                    this.preview_pdf = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearInput() {
            this.packages = [];
            this.model.transaction_date = moment().format("DD-MM-YYYY");
            this.model.driver_id = undefined;
        }
    }
};
</script>
